import React from "react"

const Logo = () => {
  return (
    <svg className="w-full h-full" viewBox="0 0 202 29">
      <path
        d="M1.68044 19.8962V28.2984H0V8.42285H7.18531C9.38726 8.42285 11.0098 8.88642 12.2266 9.8715C13.3855 10.8566 13.965 12.3052 13.965 14.1595C13.965 15.6082 13.5014 16.825 12.6322 17.8101C11.7631 18.7952 10.6041 19.4326 9.15548 19.6644L14.8921 28.2984H12.922L7.4171 19.8962H1.68044ZM1.68044 9.92945V18.4475H7.18531C8.8078 18.4475 10.0247 18.0998 10.8939 17.3465C11.7631 16.5932 12.2266 15.5502 12.2266 14.2175C12.2266 12.8847 11.7631 11.8417 10.8939 11.0884C10.0247 10.3351 8.74986 9.9874 7.18531 9.9874H1.68044V9.92945Z"
        fill="black"
      />
      <path
        d="M30.3638 26.7918V28.2984H17.4998V8.42285H30.4797V9.92945H19.1802V17.6363H29.2628V19.1429H19.1802V26.7918H30.3638Z"
        fill="black"
      />
      <path
        d="M50.8187 9.8715L43.8652 28.3563H42.3586L35.463 9.8715H35.4051V28.3563H33.7826V8.42285H36.622L43.2278 26.2123H43.2857L49.8916 8.42285H52.6151V28.3563H50.9926L50.8187 9.8715Z"
        fill="black"
      />
      <path
        d="M72.6064 28.2987H70.8101L68.4343 22.3882H58.5255L56.1497 28.2987H54.4693L62.5817 8.36523H64.494L72.6064 28.2987ZM63.5089 10.1616H63.4509L59.105 20.9395H67.8548L63.5089 10.1616Z"
        fill="black"
      />
      <path
        d="M76.1977 19.8954V28.2976H74.5173V8.42212H81.7026C83.9045 8.42212 85.527 8.88569 86.7439 9.87077C87.9028 10.8559 88.4823 12.3045 88.4823 14.1588C88.4823 15.6074 88.0187 16.8243 87.1495 17.8094C86.2803 18.7945 85.1214 19.4319 83.6727 19.6637L89.4094 28.2976H87.4392L81.9344 19.8954H76.1977ZM76.1977 9.92872V18.4468H81.7026C83.3251 18.4468 84.5419 18.0991 85.4111 17.3458C86.2803 16.5925 86.7439 15.5495 86.7439 14.2167C86.7439 12.884 86.2803 11.8409 85.4111 11.0876C84.5419 10.3343 83.2671 9.98666 81.7026 9.98666H76.1977V9.92872Z"
        fill="black"
      />
      <path
        d="M107.43 28.2976H105.402L96.8842 18.3888L93.6972 21.5759V28.2976H92.0167V8.42212H93.6972V19.6637L104.881 8.42212H107.025L98.1011 17.2299L107.43 28.2976Z"
        fill="black"
      />
      <path
        d="M122.786 26.791V28.2976H109.864V8.42212H122.844V9.92872H111.544V17.6355H121.627V19.1421H111.544V26.791H122.786Z"
        fill="black"
      />
      <path
        d="M133.737 28.2976H132.057V9.92872H124.466V8.42212H141.386V9.92872H133.795V28.2976H133.737Z"
        fill="black"
      />
      <path
        d="M145.558 28.2976H143.877V8.42212H145.558V28.2976Z"
        fill="black"
      />
      <path
        d="M149.73 8.42212H151.932L163.289 25.3424H163.347V8.42212H164.97V28.3556H163.405L151.468 10.5082H151.41V28.3556H149.788V8.42212H149.73Z"
        fill="black"
      />
      <path
        d="M176.848 28.646C174.241 28.646 172.097 27.7189 170.416 25.8067C168.736 23.8945 167.925 21.4028 167.925 18.3316C167.925 15.3185 168.794 12.8268 170.474 10.9146C172.155 9.00233 174.299 8.0752 176.906 8.0752C178.934 8.0752 180.615 8.59671 181.89 9.63974C183.164 10.6828 184.034 12.1314 184.381 13.9857H182.701C182.411 12.595 181.774 11.494 180.789 10.7407C179.804 9.98742 178.529 9.58179 176.964 9.58179C174.762 9.58179 173.024 10.393 171.691 11.9576C170.416 13.5221 169.721 15.6661 169.721 18.3316C169.721 20.9972 170.358 23.1412 171.633 24.7057C172.908 26.2703 174.646 27.0815 176.848 27.0815C178.703 27.0815 180.209 26.56 181.426 25.459C182.585 24.358 183.222 22.9673 183.222 21.171V19.9541H178.007V18.4475H184.787V28.2404H183.396L183.164 24.6478C182.817 25.8067 182.006 26.7338 180.847 27.4871C179.572 28.2984 178.239 28.646 176.848 28.646Z"
        fill="black"
      />
      <path
        d="M201.997 6.52101H195.476V0H193.116V6.52101H186.532V8.9431H193.116V15.4641H195.476V8.9431H201.997V6.52101Z"
        fill="#4B8F39"
      />
    </svg>
  )
}

export default Logo
