import React, { useState, useRef } from "react"
import { Link as ScrollLink } from "react-scroll"
import { Link } from "gatsby"
import Arrow from "../svg/arrow"

import "./button.css"

const ButtonPrimaryLoader = () => {
  return (
    <svg class="ButtonPrimaryLoader w-8 h-8 fill-current" viewBox="0 0 32 32">
      <circle
        class="ButtonPrimaryLoader__path stroke-current"
        cx="16"
        cy="16"
        r="14"
        fill="none"
        strokeWidth="4"
      ></circle>
    </svg>
  )
}

const ButtonPrimaryWrapper = ({
  children,
  component,
  type,
  isSubmitting,
  full,
  small,
  large,
  larger,
  href,
  to,
  onClick,
  label,
}) => {
  let classList = [`Button`, `Button--${type}`]

  if (isSubmitting) {
    classList.push("Button--disabled")
  }

  if (full) {
    classList.push("Button--full")
  }

  if (small) {
    classList.push("Button--small")
  }

  if (large) {
    classList.push("Button--large")
  }

  if (larger) {
    classList.push("Button--larger")
  }

  classList = classList.join(" | ")

  if (component === "scrollLink") {
    return (
      <ScrollLink
        to="contact"
        spy={true}
        smooth={true}
        duration={500}
        offset={0}
        hashSpy={true}
        className={classList}
        aria-label={label}
        href="#contact"
      >
        {children}
      </ScrollLink>
    )
  } else if (component === "submit") {
    return (
      <button type="submit" className={classList} aria-label={label}>
        {children}
      </button>
    )
  } else if (component === "a") {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className={classList}
        aria-label={label}
      >
        {children}
      </a>
    )
  } else if (component === "link") {
    return (
      <Link to={to} className={classList} aria-label={label}>
        {children}
      </Link>
    )
  } else if (component === "button") {
    return (
      <button className={classList} onClick={onClick} aria-label={label}>
        {children}
      </button>
    )
  }
}

const ButtonPrimary = ({
  children,
  component = "scrollLink",
  type = "primary",
  isSubmitting,
  full,
  small,
  large,
  larger,
  href,
  to,
  icon,
  arrow,
  onClick,
  label,
}) => {
  const button = useRef(null)
  const [size, setSize] = useState(100)
  const [opacity, setOpacity] = useState(0)
  const [x, setX] = useState(0)
  const [y, setY] = useState(0)

  const handleOnMouseEnter = event => {
    setSize(200)
    setOpacity(0.25)
  }

  const handleOnMouseLeave = event => {
    setSize(100)
    setOpacity(0)
  }

  const handleOnMouseMove = event => {
    setX(event.clientX - button.current.getBoundingClientRect().left)
    setY(event.clientY - button.current.getBoundingClientRect().top)
  }

  return (
    <ButtonPrimaryWrapper
      component={component}
      type={type}
      isSubmitting={isSubmitting}
      full={full}
      small={small}
      large={large}
      larger={larger}
      href={href}
      to={to}
      onClick={onClick}
      label={label}
    >
      <span className="Button__inner-bg"></span>
      <span
        role="button"
        tabIndex={0}
        className="Button__inner-a"
        onMouseEnter={() => handleOnMouseEnter()}
        onMouseLeave={() => handleOnMouseLeave()}
        onMouseMove={event => handleOnMouseMove(event)}
        aria-label={label}
      >
        <span ref={button} className="Button__inner-b">
          <span
            className="Button__glow"
            style={{
              "--size": `${size}px`,
              "--x": `${x}px`,
              "--y": `${y}px`,
              "--opacity": `${opacity}`,
            }}
          ></span>

          {icon ? (
            <svg
              className="w-8 h-8 lg:w-10 lg:h-10 xl:w-12 xl:h-12 text-white fill-current -mt-1 lg:-mt-1.5 -mr-2.5 lg:-mr-3 xl:-mr-5"
              viewBox="0 0 66 62"
            >
              <path d="M4.28794 40.4479V61.8667H0V11.2001H18.3346C23.9533 11.2001 28.0934 12.3818 31.1984 14.893C34.1556 17.4042 35.6342 21.0971 35.6342 25.824C35.6342 29.5169 34.4514 32.6189 32.2335 35.1301C30.0156 37.6413 27.0584 39.2662 23.3619 39.857L38 61.8667H32.9728L18.9261 40.4479H4.28794ZM4.28794 15.0407V36.755H18.3346C22.4747 36.755 25.5798 35.8687 27.7977 33.9484C30.0156 32.0281 31.1984 29.3692 31.1984 25.9717C31.1984 22.5742 30.0156 19.9153 27.7977 17.995C25.5798 16.0747 22.3268 15.1884 18.3346 15.1884H4.28794V15.0407Z" />
              <path d="M66 10.9639H55.0361V0H51.0682V10.9639H40V15.0361H51.0682V26H55.0361V15.0361H66V10.9639Z" />
            </svg>
          ) : (
            <span className="Button__text">
              <span>{isSubmitting ? <ButtonPrimaryLoader /> : children}</span>
              {arrow && (
                <span className="Button__arrow">
                  <Arrow />
                </span>
              )}
            </span>
          )}
        </span>
      </span>
    </ButtonPrimaryWrapper>
  )
}

export default ButtonPrimary
