import React from "react"

const GridHelper = ({ off }) => {
  if (off) return null

  return (
    <div className="fixed inset-0 pointer-events-none" style={{ zIndex: 9999 }}>
      <div className="h-full grid grid-cols-6 md:grid-cols-12 gap-x-gs px-ogs">
        {[...Array(12)].map((item, index) => {
          return (
            <div
              key={index}
              style={{ background: "cyan" }}
              className={`${
                index > 5 ? "hidden md:block" : ""
              } col-span-1 opacity-25`}
            />
          )
        })}
      </div>
    </div>
  )
}

export default GridHelper
