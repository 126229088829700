import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ title, description }) {
  const { prismicGlobal } = useStaticQuery(
    graphql`
      query {
        prismicGlobal {
          data {
            meta_title
            meta_description
          }
        }
      }
    `
  )

  const metaTitle = title || prismicGlobal.data.meta_title
  const metaTitleDefault = prismicGlobal.data.meta_title
  const metaDescription = description || prismicGlobal.data.meta_description
  const lang = "en"
  const meta = []

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={metaTitleDefault ? `%s | ${metaTitleDefault}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        // {
        //   property: `og:image`,
        //   content: `${site.siteMetadata.url}/${site.siteMetadata.image}`,
        // },
      ].concat(meta)}
    />
  )
}

export default SEO
