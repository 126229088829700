import React from "react"
import { Link } from "gatsby"
import { Fade } from "react-awesome-reveal"
import ButtonPrimary from "./button/buttonPrimary"
import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  const { prismicGlobal } = useStaticQuery(
    graphql`
      query {
        prismicGlobal {
          data {
            footer_heading
            footer_phone_cta
            global_email
            global_name
            global_phone
          }
        }
      }
    `
  )

  const { data } = prismicGlobal
  const year = new Date().getFullYear()

  return (
    <footer className="relative bg-green px-ogs overflow-hidden ">
      <div className="absolute inset-x-0 bottom-0 h-200 bg-gradient-a z-20"></div>
      <Fade fraction={0.5} triggerOnce className="relative py-10 md:py-28 z-30">
        <div>
          <div className="space-y-10 md:space-y-24 mb-8 text-center md:text-left">
            {data.footer_heading && (
              <h3 className="text-xl md:text-3xl lg:text-4xl text-white font-extralight leading-tight tracking-tight-3 uppercase max-w-lg">
                {data.footer_heading}
              </h3>
            )}
            <div className="space-y-2">
              {data.footer_phone_cta && (
                <p className="text-white tracking-tight-1">
                  {data.footer_phone_cta}
                </p>
              )}
              {data.global_phone && (
                <a
                  className="link text-lg tracking-tight-1"
                  href={"tel:" + data.global_phone}
                  aria-label="Phone"
                >
                  {data.global_phone}
                </a>
              )}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-x-gs gap-y-8 items-center text-center md:text-left">
            <div className="row-start-2 md:row-start-1 col-span-12 md:col-span-6 lg:col-span-8 grid grid-cols-8 gap-x-gs gap-y-2">
              <div className="col-span-8 lg:col-span-4">
                <Link
                  className="link link--small text-sm pb-px"
                  to="/terms-and-conditions"
                  aria-label="Terms and conditions"
                >
                  Terms and conditions
                </Link>
              </div>
              <div className="col-span-8 lg:col-span-4">
                <p className="text-sm text-white">
                  {data.global_name ? data.global_name : " Remarketing+"} &copy;
                  {year}. All rights reserved
                </p>
              </div>
            </div>
            <div className="row-start-1 col-span-12 md:col-span-6 lg:col-span-4">
              {data.global_email && (
                <ButtonPrimary
                  component="a"
                  href={"mailto:" + data.global_email}
                  type="quintinary"
                  full
                  large
                  label={data.global_email}
                >
                  {data.global_email}
                </ButtonPrimary>
              )}
            </div>
          </div>
        </div>
      </Fade>
    </footer>
  )
}

export default Footer
