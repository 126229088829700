import React from "react"

import GridHelper from "./grid-helper/gridHelper"
import Header from "./header/header"
import Footer from "./footer"

const Layout = ({ children, isIndexPage }) => {
  return (
    <div className="relative">
      <GridHelper off />
      <Header isIndexPage={isIndexPage} />
      <div className="relative z-10">
        {children}
        <Footer />
      </div>
    </div>
  )
}

export default Layout
