import React, { useState, useEffect } from "react"
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll"
import { Link } from "gatsby"

import Logo from "../svg/logo"
import ButtonPrimary from "../button/buttonPrimary"

import "./header.css"

const Item = ({ item, isIndexPage }) => {
  const classList = "link link--alt font-medium text-sm cursor-pointer py-2"

  if (isIndexPage) {
    return (
      <ScrollLink
        className={classList}
        activeClass="text-blue"
        to={item.to}
        spy={true}
        smooth={true}
        duration={500}
        offset={0}
        hashSpy={true}
        aria-label={item.title}
        href={`#${item.to}`}
      >
        {item.title}
      </ScrollLink>
    )
  } else {
    return (
      <Link className={classList} to={`/#${item.to}`} aria-label={item.title}>
        {item.title}
      </Link>
    )
  }
}

const Header = ({ siteTitle, isIndexPage }) => {
  const items = [
    {
      title: "Product",
      to: "product",
    },
    {
      title: "About",
      to: "about",
    },
  ]

  const [scrollDirection, setScrollDirection] = useState()
  const [previousScrollTop, setPreviousScrollTop] = useState()
  const [headerHeight, setHeaderHeight] = useState("var(--header-height)")

  const scrollToTop = () => {
    scroll.scrollToTop()
  }

  const eventScroll = () => {
    let scrollY = window.scrollY

    // set header reduced
    if (scrollY > 0) {
      if (headerHeight !== "var(--header-height-reduced)") {
        setHeaderHeight("var(--header-height-reduced)")
      }
    }

    if (scrollY <= 0) {
      if (headerHeight !== "var(--header-height)") {
        setHeaderHeight("var(--header-height)")
      }
    }

    // set scroll direction
    setScrollDirection(scrollY > previousScrollTop ? "down" : "up")

    // set previous scroll top
    setPreviousScrollTop(scrollY <= 0 ? 0 : scrollY)
  }

  useEffect(() => {
    window.addEventListener("scroll", eventScroll, false)
    return () => {
      window.removeEventListener("scroll", eventScroll, false)
    }
  })

  return (
    <header
      style={{
        top: `${
          scrollDirection === `down` ? `calc(-1 * ${headerHeight})` : `0px`
        }`,
      }}
      className="Header"
    >
      <div
        style={{ height: headerHeight }}
        className="flex items-center px-ogs bg-white transition-all"
      >
        <div className="w-full grid grid-cols-12 items-center gap-gs">
          <div className="col-span-5 lg:col-span-6">
            {isIndexPage ? (
              <button
                className="block focus:outline-none transition-all"
                onClick={scrollToTop}
                aria-label="Remarketing+"
              >
                <div className="Header__logo">
                  <Logo />
                </div>
              </button>
            ) : (
              <Link to="/" className="block transition-all">
                <div className="Header__logo">
                  <Logo />
                </div>
              </Link>
            )}
          </div>
          <div className="col-span-7 lg:col-span-6 flex items-center justify-end md:justify-between">
            {items.map((item, index) => {
              return (
                <div className="hidden md:block" key={index}>
                  <Item item={item} isIndexPage={isIndexPage} />
                </div>
              )
            })}
            {isIndexPage ? (
              <ButtonPrimary type="tertiary" label="Learn More">
                Learn More
              </ButtonPrimary>
            ) : (
              <ButtonPrimary
                type="tertiary"
                component="link"
                to="/#contact"
                label="Learn More"
              >
                Learn More
              </ButtonPrimary>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
