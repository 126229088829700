import React from "react"

const Arrow = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 72 16">
      <path d="M71.7071 8.70711C72.0976 8.31659 72.0976 7.68342 71.7071 7.2929L65.3431 0.928938C64.9526 0.538413 64.3195 0.538413 63.9289 0.928938C63.5384 1.31946 63.5384 1.95263 63.9289 2.34315L69.5858 8.00001L63.9289 13.6569C63.5384 14.0474 63.5384 14.6805 63.9289 15.0711C64.3195 15.4616 64.9526 15.4616 65.3431 15.0711L71.7071 8.70711ZM-8.74228e-08 9L71 9.00001L71 7.00001L8.74228e-08 7L-8.74228e-08 9Z" />
    </svg>
  )
}

export default Arrow
